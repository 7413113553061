<template>
<div>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="53" height="53" viewBox="0 0 47 47">
  
    <g id="Group_1196" data-name="Group 1196" transform="translate(1.553 1.5)">
      <g id="Group_157" data-name="Group 157" transform="translate(0)">
        <g transform="matrix(1, 0, 0, 1, -4.55, -4.5)">
          <g id="Ellipse_36-2" data-name="Ellipse 36" transform="translate(4.5 4.5)" fill="none" stroke="rgba(247,247,247,0.6)" stroke-width="1">
            <circle cx="22" cy="22" r="22" stroke="none"/>
            <circle cx="22" cy="22" r="21.5" fill="none"/>
          </g>
        </g>
        <path id="Path_690" class="a b" data-name="Path 690" d="M13590.832-518.431l4.781,4.629,10.457-10.042" transform="translate(-13576.208 541.183)" fill="none" stroke-linecap="round" stroke-width="2.5"/>
      </g>
    </g>
  </svg>
</div>
</template>

<script>
export default {
};
</script>

<style scoped lang="scss">
@import "~sass/modules/_variables.scss";

svg{
  display:block;
  width:3vw;
  width:var(--icon-width);
  height:3vw;
  height:var(--icon-width);
}

.a,.b,.d{fill:none;}
.a{stroke:rgba(247,247,247,0.6);}
.b{stroke:$theme-primary;stroke-linecap:round;stroke-width:2.5px;}
.c{stroke:none;}
.e{filter:none !important}
</style>